/* css/MainLayout.css */
.main-layout {
    max-width: 1440px;
    margin: 0 auto;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background: linear-gradient(180deg, #f0f9ff 0%, #ffffff 100%);
    min-height: 100vh;
}

/* Card Styles */
.card {
    background: white;
    border-radius: 4px; /* 从12px减小到8px */
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
    animation: fadeIn 0.3s ease;
    padding: 0.5rem; /* 从1.5rem减小到1rem */
}

.card:hover {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* URL Form Section */
.card-content {
    padding: 1rem; /* 从1.5rem减小到1rem */
}

/* Only apply padding to the URL form card */
.card > .card-content {
    padding: 0.5rem;
}

/* Loading Message */
.loading-message {
    text-align: center;
    color: #666;
    margin-top: 1rem;
    font-size: 0.875rem;
}

/* Navigation */
.nav-tabs {
    margin-top: 0.5rem;
}

.tabs-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
}

.tab-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    background: white;
    border: 1px solid #e5e7eb;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.2s ease;
    font-size: 1rem;
    color: #4b5563;
    gap: 0.5rem;
}

.tab-button:hover {
    background: #559ced;
    color: white;
    transform: translateY(-1px);
}

.tab-button.active {
    background: #4a90e2;
    color: white;
    border-color: #4a90e2;
}

.tab-button.active:hover {
    background: #357abd;
}

.tab-icon {
    width: 1.25rem;
    height: 1.25rem;
}

/* Content Grid */
.content-grid {
    display: grid;
    gap: 0.5rem;
    transition: all 0.3s ease;
    width: 100%;
    box-sizing: border-box;
    padding: 0.5rem;
}

.content-grid.split-view {
    grid-template-columns: 1fr 1fr;
}

.content-grid.single-column {
    grid-template-columns: 1fr;
}

/* Content Card */
.content-card {
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    overflow-y: auto;
    overflow-x: auto;
    position: relative;
    z-index: auto;
}

/* 为 Webkit 浏览器自定义垂直滚动条样式 */
.content-card::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

.content-card::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.content-card::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
}

.content-card::-webkit-scrollbar-thumb:hover {
    background: #555;
}

/* 为水平和垂直滚动条交叉处添加样式 */
.content-card::-webkit-scrollbar-corner {
    background: #f1f1f1;
}

/* Responsive Design */
@media (max-width: 1024px) {
    .main-layout {
        padding: 0.5rem;
    }
    
    .content-grid.split-view {
        grid-template-columns: 1fr;
        gap: 0.5rem;
    }
}

@media (max-width: 640px) {
    .main-layout {
        padding: 0;
        background: #f8fafc;
        width: 100%;
        max-width: 100%;
    }

    /* 内容区域样式 */
    .content-grid {
        padding: 0 4px; /* 减小到最小边距 */
        width: 100%;
        max-width: 100%;
        box-sizing: border-box;
    }

    .content-card {
        background: transparent;
        padding: 0;
        width: 100%;
        max-width: 100%;
    }

    /* URL输入区域样式 */
    .card > .card-content {
        padding: 12px 8px; /* 减小左右内边距 */
        background: white;
        margin-bottom: 8px;
        border-bottom: 1px solid #f0f0f0;
    }

    /* 标签按钮容器 */
    .tabs-container {
        padding: 8px;
        gap: 8px;
        background: white;
        margin-bottom: 8px;
        border-bottom: 1px solid #f0f0f0;
    }

    .tab-button {
        padding: 8px 12px;
        font-size: 14px;
        border-radius: 4px;
        background: #f5f5f5;
    }
}

/* Animations */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.category-tag {
    display: inline-block;
    padding: 4px 12px;
    border-radius: 16px;
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(229, 231, 235);
    color: rgb(75, 85, 99);
    font-size: 0.875rem;
    transition: all 0.2s ease;
}

.category-tag:hover {
    background-color: rgb(243, 244, 246);
    cursor: pointer;
}

/* Processing Steps UI */
.processing-steps {
    margin-top: 1rem;
    padding: 1rem;
    border-radius: 8px;
    background: linear-gradient(145deg, #ffffff, #f5f7fa);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.processing-step {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    padding: 0.75rem;
    margin: 0.5rem 0;
    border-radius: 6px;
    background: white;
    transition: all 0.3s ease;
}

.processing-step .step-icon {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    flex-shrink: 0;
}

.processing-step.completed {
    color: #059669;
    border-left: 3px solid #059669;
}

.processing-step.completed .step-icon {
    background-color: #d1fae5;
    color: #059669;
}

.processing-step.processing {
    color: #2563eb;
    border-left: 3px solid #2563eb;
    animation: slideIn 0.3s ease;
}

.processing-step.processing .step-icon {
    background-color: #dbeafe;
    color: #2563eb;
    animation: spin 2s linear infinite;
}

.processing-step .step-content {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
}

.processing-step .step-message {
    font-weight: 500;
    font-size: 0.95rem;
}

.processing-step .step-description {
    font-size: 0.8rem;
    color: #6b7280;
}

/* Progress bar for each step */
.processing-step .progress-bar {
    height: 2px;
    background: #e5e7eb;
    border-radius: 1px;
    overflow: hidden;
    margin-top: 0.5rem;
}

.processing-step.processing .progress-bar::after {
    content: '';
    display: block;
    height: 100%;
    width: 30%;
    background: #2563eb;
    animation: progress 1.5s ease infinite;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@keyframes progress {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(400%);
    }
}

@keyframes slideIn {
    from {
        opacity: 0;
        transform: translateX(-10px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

/* 移动端适配 */
@media (max-width: 640px) {
    .processing-steps {
        margin: 0.5rem;
        padding: 0.75rem;
    }

    .processing-step {
        padding: 0.5rem;
        margin: 0.25rem 0;
    }

    .processing-step .step-message {
        font-size: 0.875rem;
    }
}